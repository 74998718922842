import PropTypes from 'prop-types';
import Icon from './Icon';

const ListItem = ({
    lists,
    Type,
    listStyle,
    listFontSize,
    listColor,
    listClass,
    itemClass,
    iconSrc,
    iconWidth,
    iconHeight,
}) => {
    return (
        <Type
            className={`${iconSrc ? 'pl-0' : 'pl-4'} ${listStyle} ${listFontSize} ${listColor} ${listClass}`}
        >
            {Array.isArray(lists) &&
                lists.map((list, index) => (
                    <li
                        key={index}
                        className={`${iconSrc && 'flex gap-2'} ${itemClass}`}
                    >
                        {iconSrc && (
                            <Icon
                                src={iconSrc}
                                width={iconWidth}
                                height={iconHeight}
                                alt={list.listIconAlt}
                            />
                        )}
                        {list.item}
                    </li>
                ))}
        </Type>
    );
};

ListItem.defaultProps = {
    Type: 'ul',
    listStyle: 'list-disc',
    listColor: 'text-gray-500',
    listFontSize: 'text-base',
    listClass: '',
    iconSrc: '/images/icons/icon_check.svg',
    iconWidth: 16,
    iconHeight: 16,
};

ListItem.propTypes = {
    lists: PropTypes.arrayOf(
        PropTypes.shape({
            item: PropTypes.string.isRequired,
        })
    ).isRequired,
    Type: PropTypes.oneOf(['ul', 'ol']),
    listStyle: PropTypes.string,
    listColor: PropTypes.string,
    listFontSize: PropTypes.string,
    listClass: PropTypes.string,
    itemClass: PropTypes.string,
    iconSrc: PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
};

export default ListItem;
