import PropTypes from 'prop-types';
import Avatar from '@/atoms/Avatar';
import Text from '@/atoms/Text';
import React from 'react';

const Profile = ({
    avatarSrc,
    title,
    description,
    availableOn,
    className,
    onClick,
    showAvailableOn,
    ...rest
}) => {
    return (
        <div
            className={`flex items-center gap-3 ${className}`}
            onClick={onClick}
            {...rest}
        >
            <Avatar size="medium" shape="circle" imgSrc={avatarSrc} />
            <div>
                <Text variant="caption" fontWeight="font-medium">
                    {title}
                </Text>
                <Text
                    variant="captionSmall"
                    fontWeight="font-medium"
                    className="text-grey-shade3"
                >
                    {description}
                </Text>
                {showAvailableOn && (
                    <Text
                        variant="caption"
                        textColor="text-grey-shade1"
                        className="mt-2"
                    >
                        Available on call :
                        <span className="text-grey-shade3">{availableOn}</span>
                    </Text>
                )}
            </div>
        </div>
    );
};

Profile.propTypes = {
    avatarSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    availableOn: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    showAvailableOn: PropTypes.bool,
};

Profile.defaultProps = {
    availableOn: 'Once in 2 weeks',
    className: '',
    onClick: () => {},
    showAvailableOn: true,
};

export default Profile;
