import React from 'react';
import NextImage from 'next/image';
import Text from '@/atoms/Text';
import PropTypes from 'prop-types';

const GuidedRecoveryBannerMobile = ({ guidedRecoveryList, clasName }) => {
    return (
        <div className={clasName}>
            {guidedRecoveryList.map((item) => (
                <div className="mb-10 last:mb-0" key={item.id}>
                    <NextImage
                        loader={({ src }) => src}
                        src={item.imgSrc}
                        width={158}
                        height={222}
                        alt="psoriasis care"
                        className="mx-auto"
                    />
                    <div className="max-w-[339px] mx-auto w-full mt-5 text-center">
                        <Text
                            variant="body4"
                            fontWeight="font-medium"
                            textColor="text-grey-shade1"
                        >
                            {item.heading}
                        </Text>
                        <Text
                            variant="body1"
                            textColor="text-grey-shade2"
                            className="mt-2"
                        >
                            {item.subHeading}
                        </Text>
                    </div>
                </div>
            ))}
        </div>
    );
};

GuidedRecoveryBannerMobile.propTypes = {
    clasName: PropTypes.string,
    guidedRecoveryList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            heading: PropTypes.string,
            subHeading: PropTypes.string,
            imgSrc: PropTypes.string,
        })
    ),
};
export default GuidedRecoveryBannerMobile;
