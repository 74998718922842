import Heading from '@/atoms/Heading';
import Overview from '@/molecules/Overview';
import React from 'react';
import PropTypes from 'prop-types';

const InfoGallery = ({ list, heading, subHeading, bgColor, textColor }) => {
    return (
        <div className="min-h-[670px] flex flex-col items-center">
            <div className="lg:px-10">
                <Heading
                    type="h3"
                    fontColor="text-grey-shade1"
                    className="text-[20px]/[24px] lg:text-h3 md:text-[23px]/[28px] "
                    align="center"
                    fontFamily="font-body md:font-heading"
                >
                    {heading}
                </Heading>
                <Heading type="h6" fontColor="text-grey-shade3" align="center">
                    {subHeading}
                </Heading>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 container my-8 md:mt-[60px] md:mb-[52px] lg:mt-[103px] lg:mb-14 justify-center place-items-center">
                {list.map((item) => (
                    <Overview
                        key={item.id}
                        heading={item.heading}
                        imageAlt={item.imageAlt}
                        imageSrc={item.imageSrc}
                        number={item.number}
                        bgColor={bgColor}
                        textColor={textColor}
                    />
                ))}
            </div>
        </div>
    );
};

InfoGallery.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            heading: PropTypes.string,
            imageAlt: PropTypes.string,
            imageSrc: PropTypes.string,
            number: PropTypes.number,
        })
    ),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
};
export default InfoGallery;
