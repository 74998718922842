import PropTypes from 'prop-types';
import Image from 'next/image';
import FeatureIcon from '@/molecules/FeatureIcon';
import SectionHeading from '@/atoms/SectionHeading';
import { useEffect, useState } from 'react';
import Button from '@/atoms/Button';
import { redirectTo } from 'utils/router';
import { DEVICE_ONLY_PLAN_NAME } from 'constants/plan-constants';

const UVDeviceHightLightCard = ({
    handleComponentChange,
    showFirstList,
    showSecondList,
    className,
    handleBuyUvDevicePlanNow,
    uvDeviceHighlightCardData,
    cardTitle,
}) => {
    const featuresList = uvDeviceHighlightCardData.bullets.map((list) => {
        return {
            icon: list.bullet_icon.url,
            title: list.title,
            desc: list.subtitle,
        };
    });

    const myLoader = ({ src }) => src;

    const [showElement, setShowElement] = useState(false);

    useEffect(() => {
        setShowElement(true);
    }, []);

    return (
        <div className={`container ${className}`}>
            <div className="bg-secondary-shade3 px-4 py-8 md:py-[60px] md:px-10 rounded-[20px]">
                <SectionHeading
                    align="center"
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                >
                    {cardTitle}
                </SectionHeading>
                <div
                    className={`flex justify-between gap-12 lg:gap-0 my-8 md:mt-16 md:mb-[93px] lg:my-[110px] pb-1 transition-all duration-1000 ${showElement ? 'opacity-100' : 'opacity-0'}`}
                    onClick={handleComponentChange}
                >
                    {showFirstList && (
                        <div className="flex flex-col gap-16">
                            {featuresList.slice(0, 3).map((list, index) => (
                                <div
                                    key={index}
                                    data-aos="fade-up"
                                    data-aos-delay={index * 200}
                                    data-aos-once="true"
                                >
                                    <FeatureIcon
                                        icon={list.icon}
                                        title={list.title}
                                        desc={list.desc}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    <div
                        className="hidden md:flex items-center justify-center"
                        data-aos="zoom-out"
                        data-aos-duration="2000"
                        data-aos-once="true"
                    >
                        <Image
                            loader={myLoader}
                            src={uvDeviceHighlightCardData.image}
                            width={531}
                            height={531}
                            alt="device"
                            className={`${showElement ? 'scale-90' : 'scale-100'} transition-transform duration-500 hidden lg:block`}
                        />
                    </div>

                    {showSecondList && (
                        <div
                            className={`flex flex-col gap-16 transition-all duration-1000 ${showElement ? 'opacity-100' : 'opacity-0'}`}
                        >
                            {featuresList.slice(3).map((list, index) => (
                                <div
                                    key={index}
                                    data-aos="fade-up"
                                    data-aos-delay={index * 200}
                                    data-aos-once="true"
                                >
                                    <FeatureIcon
                                        icon={list.icon}
                                        title={list.title}
                                        desc={list.desc}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="hidden md:flex flex-col xsm:flex-row justify-center gap-5">
                    <Button
                        label="Buy Now"
                        style="primary"
                        size="large"
                        onClick={handleBuyUvDevicePlanNow}
                    />
                    <Button
                        label="Know more"
                        style="ghost"
                        size="large"
                        onClick={() => {
                            redirectTo(`/plans/${DEVICE_ONLY_PLAN_NAME}`);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
UVDeviceHightLightCard.defaultProps = {
    handleComponentChange: () => {},
    showFirstList: true,
    showSecondList: true,
    className: '',
    handleBuyUvDevicePlanNow: () => {},
};

UVDeviceHightLightCard.propTypes = {
    handleComponentChange: PropTypes.func,
    showFirstList: PropTypes.bool,
    showSecondList: PropTypes.bool,
    className: PropTypes.string,
    handleBuyUvDevicePlanNow: PropTypes.func,
    uvDeviceHighlightCardData: PropTypes.object,
    cardTitle: PropTypes.string,
};

export default UVDeviceHightLightCard;
