import { useReducer } from 'react';
import PropTypes from 'prop-types';
import Icon from '@/atoms/Icon';
import Image from 'next/image';

const reducer = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                rangeValue: action.payload,
            };
        case 'move':
            return {
                rangeValue: Math.round(action.payload),
            };
        default:
            return state;
    }
};

const BeforeAfter = ({
    beforeImgDesktop,
    beforeImgTab,
    beforeImgMobile,
    afterImgDesktop,
    afterImgTab,
    afterImgMobile,
    imgWidth,
    imgHeight,
    onChange,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    pointerMove = false,
    className = 'before-after-slider',
    beforeClassName = 'before',
    afterClassName = 'after',
    buttonClassName = 'resize-button',
    beforeStyle,
    afterStyle,
    children,
}) => {
    const myLoader = ({ src }) => src;
    const [{ rangeValue }, dispatch] = useReducer(reducer, {
        rangeValue: 50,
    });

    const handleChange = (event) => {
        dispatch({ type: 'change', payload: Number(event.target.value) });
        if (onChange) onChange(event);
    };

    const handlePointerMove = (event) => {
        const { clientX, currentTarget } = event;
        const { left, width } = currentTarget.getBoundingClientRect();
        const positionX = clientX - left;

        if (positionX >= 0)
            dispatch({ type: 'move', payload: (positionX / width) * 100 });

        if (onPointerMove) onPointerMove(event);
    };

    const handlePointerEnter = (event) => {
        if (onPointerEnter) onPointerEnter(event);
    };

    const handlePointerLeave = (event) => {
        if (onPointerLeave) onPointerLeave(event);
    };

    return (
        <div
            className={`w-fit relative overflow-hidden cursor-ew-resize select-none ${className}`}
            onPointerMove={pointerMove ? handlePointerMove : undefined}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
        >
            <div
                className={`absolute overflow-hidden h-full top-0 left-0 border-r border-white ${beforeClassName}`}
                style={{
                    width: `${rangeValue}%`,
                    ...beforeStyle,
                }}
            >
                <Image
                    loader={myLoader}
                    src={beforeImgDesktop}
                    width={imgWidth}
                    height={imgHeight}
                    className="max-w-max h-full hidden lg:block"
                    alt="Before Desktop"
                />
                <Image
                    loader={myLoader}
                    src={beforeImgTab}
                    width={620}
                    height={imgHeight}
                    className="max-w-max h-full hidden md:block lg:hidden"
                    alt="Before Tablet"
                />
                <Image
                    loader={myLoader}
                    src={beforeImgMobile}
                    width={630}
                    height={imgHeight}
                    className="max-w-max h-full block md:hidden"
                    alt="Before Mobile"
                />
            </div>

            <div className={afterClassName} style={afterStyle}>
                <Image
                    loader={myLoader}
                    src={afterImgDesktop}
                    width={imgWidth}
                    height={imgHeight}
                    alt="After Desktop"
                    className="hidden lg:block"
                />
                <Image
                    loader={myLoader}
                    src={afterImgTab}
                    width={620}
                    height={imgHeight}
                    alt="After Tablet"
                    className="hidden md:block lg:hidden"
                />
                <Image
                    loader={myLoader}
                    src={afterImgMobile}
                    width={630}
                    height={imgHeight}
                    alt="After Mobile"
                    className="block md:hidden"
                />
            </div>

            {!pointerMove && (
                <>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={rangeValue}
                        name="slider"
                        onChange={handleChange}
                        className="appearance-none bg-transparent w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-ew-resize"
                    />
                    <div
                        className={`pointer-events-none absolute top-1/2 -ml-5 transform -translate-y-1/2 rounded-full w-30 h-30 flex justify-center items-center ${buttonClassName}`}
                        style={{
                            left: `${rangeValue}%`,
                        }}
                    >
                        <Icon
                            src="images/icons/icon_drag.svg"
                            width={40}
                            height={40}
                            alt="drag"
                        />
                    </div>
                </>
            )}
            {children}
        </div>
    );
};

BeforeAfter.propTypes = {
    beforeImgDesktop: PropTypes.string.isRequired,
    beforeImgTab: PropTypes.string.isRequired,
    beforeImgMobile: PropTypes.string.isRequired,
    afterImgDesktop: PropTypes.string.isRequired,
    afterImgTab: PropTypes.string.isRequired,
    afterImgMobile: PropTypes.string.isRequired,
    imgWidth: PropTypes.number.isRequired,
    imgHeight: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    onPointerMove: PropTypes.func,
    onPointerEnter: PropTypes.func,
    onPointerLeave: PropTypes.func,
    pointerMove: PropTypes.bool,
    className: PropTypes.string,
    beforeClassName: PropTypes.string,
    afterClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    beforeStyle: PropTypes.object,
    afterStyle: PropTypes.object,
    children: PropTypes.node,
    mobileImageWidth: PropTypes.number,
};

export default BeforeAfter;
