import PropTypes from 'prop-types';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import UVTherapyCard from './UVTherapyCard';
import SectionHeading from '@/atoms/SectionHeading';
import Text from '@/atoms/Text';

const UVTherapyCardSlider = ({ homePageTestimonialData }) => {
    return (
        <>
            <div className="container text-center">
                <div className="max-[710px] mx-auto mb-9">
                    <SectionHeading
                        align="center"
                        fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl mb-2"
                    >
                        {homePageTestimonialData.title.rendered}
                    </SectionHeading>
                    <Text
                        fontSize="text-body1 md:text-body3"
                        fontWeight="font-medium"
                        className="text-center max-w-[570px] lg:max-w-max mx-auto"
                    >
                        {homePageTestimonialData.acf.sub_heading}
                    </Text>
                </div>
            </div>
            <div className="container overflow-visible">
                <Slider
                    slidesPerView={1.1}
                    spaceBetween={20}
                    loop={false}
                    allowTouchMove={false}
                    style={{
                        overflow: 'visible',
                        paddingBottom: '80px',
                    }}
                >
                    {homePageTestimonialData.acf.testimonials_data.map(
                        (list, idx) => (
                            <SwiperSlide key={idx}>
                                <UVTherapyCard
                                    beforeImg={list.before_image}
                                    afterImg={list.after_image}
                                    // title={list.title}
                                    desc={list.subtext}
                                    profileImg={list.profile_image}
                                    profileName={list.profile_name}
                                />
                            </SwiperSlide>
                        )
                    )}
                </Slider>
            </div>
        </>
    );
};

UVTherapyCardSlider.defaultProps = {};

UVTherapyCardSlider.propTypes = {
    lists: PropTypes.arrayOf(
        PropTypes.shape({
            beforeImg: PropTypes.string,
            afterImg: PropTypes.string,
            title: PropTypes.string,
            desc: PropTypes.string,
            profileImg: PropTypes.string,
            profileName: PropTypes.string,
        })
    ),
    homePageTestimonialData: PropTypes.object,
};

export default UVTherapyCardSlider;
