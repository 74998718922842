import PropTypes from 'prop-types';
import Text from '@/atoms/Text';
import React from 'react';
import NextImage from 'next/image';

const GuidedRecoveryBanner = ({
    className,
    guidedRecoveryBannerData,
    slide1Image,
}) => {
    return (
        <div className={`container ${className || ''}`}>
            <div className="flex lg:items-center  justify-between">
                <div
                    className="max-w-[262px] w-full h-fit"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-once="true"
                >
                    <div className="max-w-[220px] w-full mb-5 ">
                        <Text
                            variant="body4"
                            textColor="text-grey-shade1"
                            fontWeight="font-medium"
                        >
                            {guidedRecoveryBannerData[0].heading}
                        </Text>
                        <Text
                            variant="body1"
                            textColor="text-grey-shade2"
                            className="mt-2"
                        >
                            {guidedRecoveryBannerData[0].subHeading}
                        </Text>
                    </div>
                    <NextImage
                        src="/images/line.svg"
                        alt="line"
                        width={262}
                        height={2}
                        className="hidden lg:block"
                    />
                </div>

                <div className="relative  items-center hidden lg:flex">
                    <NextImage
                        loader={({ src }) => src}
                        src={slide1Image}
                        alt="Whole Team Coaches image"
                        width={624}
                        height={604}
                        className="px-4"
                    />
                </div>

                <div
                    className="flex items-end flex-col max-w-[262px] w-full h-fit"
                    data-aos="fade-left"
                    data-aos-delay="400"
                    data-aos-once="true"
                >
                    <div className="max-w-[220px] w-full mb-5 text-right">
                        <Text
                            variant="body4"
                            textColor="text-grey-shade1"
                            fontWeight="font-medium"
                        >
                            {guidedRecoveryBannerData[1].heading}
                        </Text>
                        <Text
                            variant="body1"
                            textColor="text-grey-shade2"
                            className="mt-2"
                        >
                            {guidedRecoveryBannerData[1].subHeading}
                        </Text>
                    </div>
                    <NextImage
                        src="/images/line.svg"
                        alt="line"
                        width={262}
                        height={2}
                        className="transform rotate-180 hidden lg:block"
                    />
                </div>
            </div>
            <div className="relative block lg:hidden">
                <NextImage
                    loader={({ src }) => src}
                    src={slide1Image}
                    alt="Whole Team Coaches image"
                    width={468}
                    height={411}
                    className="mx-auto"
                />
            </div>
            <div className="max-w-[340px] w-full mx-auto text-center mt-5 lg:-mt-9 relative z-10">
                <Text
                    variant="body4"
                    textColor="text-grey-shade1"
                    fontWeight="font-medium"
                >
                    {guidedRecoveryBannerData[2].heading}
                </Text>
                <Text
                    variant="body1"
                    textColor="text-grey-shade2"
                    className="mt-2 lg:mb-0 mb-20"
                >
                    {guidedRecoveryBannerData[2].subHeading}
                </Text>
            </div>
        </div>
    );
};

GuidedRecoveryBanner.defaultProps = {
    className: '',
};

GuidedRecoveryBanner.propTypes = {
    className: PropTypes.string,
    guidedRecoveryBannerData: PropTypes.array,
    slide1Image: PropTypes.string,
};

export default GuidedRecoveryBanner;
