import PropTypes from 'prop-types';
import Text from '@/atoms/Text';
import ReadMore from '@/atoms/ReadMore';
import Icon from '@/atoms/Icon';

const TestimonialCard = ({
    className,
    rounded,
    bgColor,
    bgOpacity,
    desc,
    descMaxLength,
    profileImg,
    profileName,
}) => {
    const myLoader = ({ src }) => src;

    return (
        <div
            className={`bg-grey-shade6  py-5 px-4 ${bgColor} ${bgOpacity} ${rounded} ${className}`}
        >
            <ReadMore
                text={`${desc}`}
                maxLength={descMaxLength}
                className="hidden md:block"
            ></ReadMore>
            <Text
                fontSize="text-body1 md:text-body3"
                fontWeight="font-normal"
                textColor="text-grey-shade1"
                className="md:hidden"
            >
                ${desc}
            </Text>
            <div className="flex items-center mt-4 gap-2">
                <Icon
                    loader={myLoader}
                    src={profileImg}
                    width={44}
                    height={44}
                    alt={profileName}
                    className="rounded-full overflow-hidden"
                />
                <Text variant="body2" textColor="text=-grey-shade1">
                    {profileName}
                </Text>
            </div>
        </div>
    );
};

TestimonialCard.defaultProps = {
    className: '',
    rounded: 'rounded-b-xl md:rounded-xl',
    bgColor: 'bg-grey-shade6',
    bgOpacity: 'bg-opacity-100 md:bg-opacity-60',
    desc: '',
    descMaxLength: 100,
    profileImg: '',
    profileName: '',
};

TestimonialCard.propTypes = {
    className: PropTypes.string,
    rounded: PropTypes.string,
    bgColor: PropTypes.string,
    bgOpacity: PropTypes.string,
    desc: PropTypes.string,
    descMaxLength: PropTypes.number,
    profileImg: PropTypes.string,
    profileName: PropTypes.string,
};

export default TestimonialCard;
