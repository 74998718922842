import { useEffect, useState } from 'react';
import Home from '@/templates/Home';
import { DEVICE_ONLY_PLAN, CARE_PLAN } from 'constants/plan-constants';
import {
    getPsoriasisWebsiteBlogs,
    getPsoriasisWebsiteCategories,
} from '@/services/psoriasis_website_blogs.service';
import { usePlanStore } from 'store/plans';
import { getAuth, setAuth } from '@/services/identity.service';
import { redirectTo } from 'utils/router';
import { useProfileStore } from 'store/profile';
import PropTypes from 'prop-types';

import {
    getAllPsoriasisWebsiteFaqsCategoris,
    getPsoriasisWebsiteFaqs,
} from '@/services/psoriasis-website-faqs.service';
import {
    getHeroBannerContents,
    getHomePageTestimonial,
    getPsoriasisCareLevel,
    getUvDeviceSection,
} from '@/services/cms.service';

export async function getServerSideProps() {
    const [
        faqResponse,
        faqCategoryResponse,
        blogsResponse,
        categoriesResponse,
        heroBannerResponse,
        homePageTestimonialResponse,
        psoriasisCareLevelResponse,
        uvDeviceSectionResponse,
    ] = await Promise.all([
        getPsoriasisWebsiteFaqs(),
        getAllPsoriasisWebsiteFaqsCategoris(),
        getPsoriasisWebsiteBlogs(),
        getPsoriasisWebsiteCategories(),
        getHeroBannerContents(),
        getHomePageTestimonial(),
        getPsoriasisCareLevel(),
        getUvDeviceSection(),
    ]);

    console.log('response', {
        faqResponse,
        faqCategoryResponse,
        blogsResponse,
        categoriesResponse,
        heroBannerResponse,
        homePageTestimonialResponse,
        psoriasisCareLevelResponse,
        uvDeviceSectionResponse,
    });

    const uvDeviceSectionData = uvDeviceSectionResponse.length
        ? uvDeviceSectionResponse
        : [];
    const psoriasisCareLevelData = psoriasisCareLevelResponse.length
        ? psoriasisCareLevelResponse
        : [];
    const homePageTestimonialData = homePageTestimonialResponse.length
        ? homePageTestimonialResponse[0]
        : {};
    const heroBannerData = heroBannerResponse.length
        ? heroBannerResponse[0]
        : {};
    const categories = categoriesResponse.length ? categoriesResponse : [];
    const response = blogsResponse.status ? blogsResponse : [];
    const faqs = faqResponse.status ? faqResponse.entity : [];
    const faqCategory = faqCategoryResponse.length ? faqCategoryResponse : [];
    const blogs = response.entity.map((entry) => ({
        ...entry.content,
        blogId: entry.id,
    }));
    const blogContent = blogs.map((blogContent) => ({
        id: blogContent?.order,
        subtitle: blogContent?.subtitle,
        blogId: blogContent.blogId,
        category: blogContent?.category,
        blogImg: blogContent?.feature_image,
        blogTitle: blogContent?.title,
        profileImg: blogContent?.author_profile_pic,
        profileName: blogContent?.author_name,
        profileDesignation: blogContent?.author_tagline,
        time: blogContent?.read_time,
        blogType: blogContent?.type_of_conntent,
    }));

    const faqData = faqCategory
        ?.map((faq) => {
            return {
                id: faq.acf.order,
                label: faq.name,
                active: faq.acf.order === 1,
                sections: [],
            };
        })
        .sort(
            (firstCategory, secondCategory) =>
                firstCategory.id - secondCategory.id
        );

    const FaqList = faqData.map((faq) => {
        const filteredFaqs = faqs
            .filter((el) => el.content.category.name === faq.label)
            .map((el) => ({
                title: el.content.title,
                content: el.content.answer,
            }));

        return {
            ...faq,
            sections: filteredFaqs,
        };
    });
    return {
        props: {
            FaqList,
            blogContent,
            categories,
            heroBannerData,
            homePageTestimonialData,
            psoriasisCareLevelData,
            uvDeviceSectionData,
        },
    };
}

const Index = ({
    FaqList,
    blogContent,
    categories,
    heroBannerData,
    homePageTestimonialData,
    psoriasisCareLevelData,
    uvDeviceSectionData,
}) => {
    const plans = usePlanStore((state) => state.plans);
    const [uvDevicePlanDetails, setUvDevicePlanDetails] = useState({});
    const [carePlanDetails, setCarePlanDetails] = useState({});
    const setSelectedPlanId = usePlanStore((state) => state.setSelectedPlanId);
    const setOpenCart = usePlanStore((state) => state.setOpenCart);
    const ordersCount = useProfileStore((state) => state.ordersCount);

    const auth = getAuth();

    useEffect(() => {
        let uvDevicePlan = {};
        let carePlan = {};
        plans.forEach((plan) => {
            if (plan.name === DEVICE_ONLY_PLAN) {
                uvDevicePlan = {
                    id: plan.id,
                    planImg: '/images/plan_img.png',
                    mobilePlanImg: '/images/mob_device_only.png',
                    planHeading: plan.name,
                    planPrice: `₹${plan.price}`,
                    planPriceText: '/ for 12 weeks',
                    featureList: [
                        {
                            title: 'Dermatologist-recommended and safe to use',
                        },
                        {
                            title: 'Compact design lets you carry it anywhere',
                        },
                        {
                            title: 'Control over when in the day you use it',
                        },
                    ],
                };
            } else if (plan.name === CARE_PLAN) {
                carePlan = {
                    id: plan.id,
                    planImg: '/images/uv_wellness.png',
                    mobilePlanImg: '/images/mob_uv_wellness.png',
                    planHeading: plan.name,
                    planPrice: `₹${plan.price}`,
                    planPriceText: '/ for 12 weeks',
                    listIcon: '/images/icons/icon_check.svg',
                    featureList: [
                        {
                            title: 'Advanced UV therapy at home',
                        },
                        {
                            title: 'Personalised meal plans to ease symptoms',
                        },
                        {
                            title: 'Wellness exercises to manage stress',
                        },
                    ],
                };
            }
        });
        setUvDevicePlanDetails(uvDevicePlan);
        setCarePlanDetails(carePlan);
    }, [plans]);
    const handleBuyUvDevicePlan = () => {
        if (auth?.id_token && ordersCount > 0) {
            redirectTo('/purchase-history');
            return;
        }
        setSelectedPlanId(uvDevicePlanDetails.id);
        setAuth({ planId: uvDevicePlanDetails.id });
        setOpenCart(true);
    };

    const handleBuyCarePlan = () => {
        if (auth?.id_token && ordersCount > 0) {
            redirectTo('/purchase-history');
            return;
        }
        setSelectedPlanId(carePlanDetails.id);
        setAuth({ planId: carePlanDetails.id });
        setOpenCart(true);
    };

    const UVTherapyList = [
        {
            id: 1,
            beforeImg: '/images/uv_therapy_before.jpg',
            afterImg: '/images/uv_therapy_after.jpg',
            desc: 'This is a place-holder testimonial of the patient improvement. This is a place-holder testimonial of the patient improvement.',
            profileImg: '/images/profile_1.svg',
            profileName: 'Rose Fernandes',
        },
        {
            id: 2,
            beforeImg: '/images/uv_therapy_before.jpg',
            afterImg: '/images/uv_therapy_after.jpg',
            desc: 'This is a place-holder testimonial of the patient improvement. This is a place-holder testimonial of the patient improvement.',
            profileImg: '/images/profile_1.svg',
            profileName: 'Rose Fernandes',
        },
    ];

    const [faqList, setListState] = useState(FaqList);
    const handleToggleList = (id) => {
        setListState((prevState) =>
            prevState.map((item) => ({
                ...item,
                active: item.id === id,
            }))
        );
    };

    const UVUltraPlan = {
        planImg: '/images/plan_img.png',
        planHeading: 'Care Plan UV Ultra',
        planPrice: '₹18,000',
        planPriceText: '/ for 12 weeks',
        listIcon: '/images/icons/icon_check.svg',
        featureList: [
            {
                title: 'Dermatologist-recommended and safe to use',
            },
            {
                title: 'Compact design lets you carry it anywhere',
            },
            {
                title: 'Control over when in the day you use it',
            },
        ],
    };

    const UVWellnessPlan = {
        planImg: '/images/uv_wellness.png',
        planHeading: 'Care Plan Wellness',
        planPrice: '₹21,000',
        planPriceText: '/ for 12 weeks',
        listIcon: '/images/icons/icon_check.svg',
        featureList: [
            {
                title: 'Advanced UV therapy at home',
            },
            {
                title: 'Personalised meal plans to ease symptoms',
            },
            {
                title: 'Wellness exercises to manage stress',
            },
        ],
    };
    const handleBuyNow = () => {
        if (auth?.id_token && ordersCount > 0) {
            redirectTo('/purchase-history');
            return;
        }
        handleBuyCarePlan();
    };
    return (
        <Home
            handleBuyNow={handleBuyNow}
            handleBuyUvDevicePlanNow={handleBuyUvDevicePlan}
            handleBuyCarePlan={handleBuyCarePlan}
            UVTherapyList={UVTherapyList}
            faqList={faqList}
            handleToggleList={handleToggleList}
            UVUltraPlan={UVUltraPlan}
            UVWellnessPlan={UVWellnessPlan}
            blogContent={blogContent}
            uvDevicePlanDetails={uvDevicePlanDetails}
            carePlanDetails={carePlanDetails}
            register={() => {}}
            setValue={() => {}}
            getValues={() => {}}
            handleSubmit={() => {}}
            onSubmit={() => {}}
            errors={{}}
            handleMobileChange={() => {}}
            handleAcknowledge={() => {}}
            isCheckedAcknowledge={false}
            handleCallSMS={() => {}}
            isCheckedCallSMS={false}
            handleCallBack={() => {}}
            categories={categories}
            heroBannerData={heroBannerData}
            homePageTestimonialData={homePageTestimonialData}
            psoriasisCareLevelData={psoriasisCareLevelData}
            uvDeviceSectionData={uvDeviceSectionData}
        />
    );
};

export default Index;

Index.propTypes = {
    blogContent: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            subtitle: PropTypes.array.isRequired,
            blogId: PropTypes.number.isRequired,
            category: PropTypes.array.isRequired,
            blogImg: PropTypes.string.isRequired,
            blogTitle: PropTypes.string.isRequired,
            profileImg: PropTypes.string.isRequired,
            profileName: PropTypes.string.isRequired,
            profileDesignation: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
            blogType: PropTypes.string.isRequired,
        })
    ),
    FaqList: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    heroBannerData: PropTypes.object.isRequired,
    homePageTestimonialData: PropTypes.object.isRequired,
    psoriasisCareLevelData: PropTypes.array.isRequired,
    uvDeviceSectionData: PropTypes.array.isRequired,
};
