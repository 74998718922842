import PropTypes from 'prop-types';
import BeforeAfter from './BeforeAfter';
import TestimonialCard from './TestimonialCard';
import Text from '@/atoms/Text';

const UVTherapyCard = ({
    beforeImgDesktop,
    beforeImgTab,
    beforeImgMobile,
    afterImgDesktop,
    afterImgTab,
    afterImgMobile,
    title,
    desc,
    profileImg,
    profileName,
}) => {
    return (
        <>
            <BeforeAfter
                className="rounded-t-xl md:rounded-[20px]"
                beforeImgDesktop={beforeImgDesktop}
                beforeImgTab={beforeImgTab}
                beforeImgMobile={beforeImgMobile}
                afterImgDesktop={afterImgDesktop}
                afterImgTab={afterImgTab}
                afterImgMobile={afterImgMobile}
                imgWidth={1083}
                imgHeight={565}
                mobileImageWidth={545}
            ></BeforeAfter>
            <Text
                fontSize="text-caption md:text-subHeading"
                className="absolute bg-grey-shade3 bg-opacity-60 px-2 py-1.5 md:px-5 md:py-3 top-2 left-2 md:top-8 md:left-8 rounded-xl"
                key={2}
                textColor="text-white"
            >
                Before {title}
            </Text>

            <TestimonialCard
                key={3}
                className=" md:max-w-[218px] md:absolute bottom-8 left-8 z-20 cursor-pointer p-4 md:px-4 md:py-5"
                desc={desc}
                descMaxLength={61}
                profileImg={profileImg}
                profileName={profileName}
            />
            <Text
                fontSize="text-caption md:text-subHeading"
                className="absolute bg-grey-shade3 bg-opacity-60 px-2 py-1.5 md:px-5 md:py-3 top-2 right-2 md:top-8 md:right-8 rounded-xl"
                key={2}
                textColor="text-white"
            >
                After {title}
            </Text>
        </>
    );
};

UVTherapyCard.defaultProps = {
    beforeImgDesktop: '/images/uv_therapy_before.jpg',
    afterImgDesktop: '/images/uv_therapy_after.jpg',
    beforeImgTab: '/images/uv_therapy_before_tab.jpg',
    afterImgTab: '/images/uv_therapy_after_tab.jpg',
    beforeImgMobile: '/images/uv_therapy_before_mob.jpg',
    afterImgMobile: '/images/uv_therapy_after_mob.jpg',
    title: 'UV Therapy',
    desc: '',
    profileImg: '',
    profileName: '',
};

UVTherapyCard.propTypes = {
    beforeImgDesktop: PropTypes.string,
    beforeImgTab: PropTypes.string,
    beforeImgMobile: PropTypes.string,
    afterImgDesktop: PropTypes.string,
    afterImgTab: PropTypes.string,
    afterImgMobile: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    profileImg: PropTypes.string,
    profileName: PropTypes.string,
};

export default UVTherapyCard;
