import Text from '@/atoms/Text';
import PropTypes from 'prop-types';
import Icon from '@/atoms/Icon';

const FeatureIcon = ({
    icon,
    iconWidth,
    iconHeight,
    title,
    titleVariant,
    titleFontWeight,
    titleColor,
    desc,
    descVariant,
    descFontWeight,
    descColor,
}) => {
    return (
        <div className="flex flex-col gap-2 text-center">
            <Icon
                src={icon}
                width={iconWidth}
                height={iconHeight}
                className="mx-auto"
                alt="icon"
            />
            <Text
                variant={titleVariant}
                fontWeight={titleFontWeight}
                textColor={titleColor}
            >
                {title}
            </Text>
            <Text
                variant={descVariant}
                fontWeight={descFontWeight}
                textColor={descColor}
            >
                {desc}
            </Text>
        </div>
    );
};

FeatureIcon.defaultProps = {
    icon: '/images/icons/icon_connectivity.svg',
    iconWidth: 32,
    iconHeight: 32,
    title: 'Seamless app connectivity',
    titleVariant: 'subHeading',
    titleFontWeight: 'font-medium',
    titleColor: 'text-grey-shade1',
    desc: 'Check upcoming sessions, control the device, and talk to experts, all in one place.',
    descVariant: 'body2',
    descFontWeight: 'font-normal',
    descColor: 'text-grey-shade1',
};

FeatureIcon.propTypes = {
    productImg: PropTypes.string,
    icon: PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    title: PropTypes.string,
    titleVariant: PropTypes.string,
    titleFontWeight: PropTypes.string,
    titleColor: PropTypes.string,
    desc: PropTypes.string,
    descVariant: PropTypes.string,
    descFontWeight: PropTypes.string,
    descColor: PropTypes.string,
};

export default FeatureIcon;
