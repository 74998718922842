import PropTypes from 'prop-types';
import Image from 'next/image';
import SectionHeading from '@/atoms/SectionHeading';
import Text from '@/atoms/Text';
import Button from '@/atoms/Button';
import { redirectTo } from 'utils/router';
import { DEVICE_ONLY_PLAN_NAME } from 'constants/plan-constants';

const UVDeviceFeatureCard = ({
    handleComponentChange,
    handleBuyUvDevicePlanNow,
    uvDeviceFeatureCardData,
    cardTitle,
}) => {
    const myLoader = ({ src }) => src;
    return (
        <div className="container">
            <div className="bg-grey-shade6 py-8 px-4 md:px-10 lg:px-20 md:py-[60px] rounded-[20px]">
                <SectionHeading
                    align="center"
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                >
                    {cardTitle}
                </SectionHeading>
                <div
                    className="relative justify-between hidden md:flex w-full mt-8 md:mb-[180px] md:mt-[195px] lg:mt-16 lg:mb-0"
                    onClick={handleComponentChange}
                >
                    <div
                        className="flex flex-col gap-4 absolute left-0 top-[-110px] lg:top-0 lg:max-w-max max-w-[158px]"
                        data-aos="fade-right"
                        data-aos-delay="300"
                        data-aos-once="true"
                    >
                        {' '}
                        <div className="md:max-w-[72px] lg:ma-w-[100px]">
                            <Image
                                loader={myLoader}
                                src={
                                    uvDeviceFeatureCardData.bullet[0]
                                        .bullet_image
                                }
                                width={100}
                                height={100}
                                alt="device"
                            />
                        </div>
                        <Image
                            src="/images/device_line_1.svg"
                            width={378}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body2"
                            textColor="text-grey-shade1"
                            className="max-w-[176px]"
                        >
                            <span className="font-medium block">
                                {uvDeviceFeatureCardData.bullet[0].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[0].subtitle}
                        </Text>
                    </div>

                    <div
                        className="flex flex-col gap-4 absolute left-0 bottom-[-110px] lg:bottom-0 lg:max-w-max max-w-[158px]"
                        data-aos="fade-right"
                        data-aos-delay="700"
                        data-aos-once="true"
                    >
                        <div className="md:max-w-[72px] lg:ma-w-[100px]">
                            <Image
                                loader={myLoader}
                                src={
                                    uvDeviceFeatureCardData.bullet[1]
                                        .bullet_image
                                }
                                width={100}
                                height={100}
                                alt="device"
                            />
                        </div>
                        <Image
                            src="/images/device_line_2.svg"
                            width={264}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body2"
                            textColor="text-grey-shade1"
                            className="max-w-[188px]"
                        >
                            <span className="font-medium block">
                                {uvDeviceFeatureCardData.bullet[1].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[1].subtitle}
                        </Text>
                    </div>

                    <div
                        className="flex items-center justify-center lg:max-w-[580px] max-w-[328px] mx-auto"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.image}
                            width={580}
                            height={580}
                            alt="device"
                        />
                    </div>

                    <div
                        className="flex flex-col items-end gap-4 absolute lg:max-w-max max-w-[158px] right-0 top-0 lg:top-1/4"
                        data-aos="fade-left"
                        data-aos-delay="1000"
                        data-aos-once="true"
                    >
                        <div className="md:max-w-[72px] lg:ma-w-[100px]">
                            <Image
                                loader={myLoader}
                                src={
                                    uvDeviceFeatureCardData.bullet[2]
                                        .bullet_image
                                }
                                width={100}
                                height={100}
                                alt="device"
                            />
                        </div>
                        <Image
                            src="/images/device_line_3.svg"
                            width={306}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body2"
                            textColor="text-grey-shade1"
                            className="max-w-[154px] text-right"
                        >
                            <span className="font-medium block">
                                {uvDeviceFeatureCardData.bullet[2].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[2].subtitle}
                        </Text>
                    </div>
                </div>

                <div className="w-fit mx-auto md:hidden mt-8">
                    <Image
                        loader={myLoader}
                        src={uvDeviceFeatureCardData.image}
                        width={215}
                        height={215}
                        alt="device"
                        className="mx-auto"
                    />
                    <div className="flex gap-4 items-center mt-8">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[0].bullet_image}
                            width={64}
                            height={64}
                            alt="device"
                        />
                        <div>
                            <Text
                                variant="body2"
                                textColor="text-grey-shade1"
                                fontWeight="font-medium"
                            >
                                {uvDeviceFeatureCardData.bullet[0].title}
                            </Text>
                            <Text variant="body2" textColor="text-grey-shade1">
                                {uvDeviceFeatureCardData.bullet[0].subtitle}
                            </Text>
                        </div>
                    </div>
                    <div className="flex gap-4 items-center mt-8">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[1].bullet_image}
                            width={64}
                            height={64}
                            alt="device"
                        />
                        <div>
                            <Text
                                variant="body2"
                                textColor="text-grey-shade1"
                                fontWeight="font-medium"
                            >
                                {uvDeviceFeatureCardData.bullet[1].title}
                            </Text>
                            <Text variant="body2" textColor="text-grey-shade1">
                                {uvDeviceFeatureCardData.bullet[1].subtitle}
                            </Text>
                        </div>
                    </div>

                    <div className="flex gap-4 items-center mt-8">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[2].bullet_image}
                            width={64}
                            height={64}
                            alt="device"
                        />
                        <div>
                            <Text
                                variant="body2"
                                textColor="text-grey-shade1"
                                fontWeight="font-medium"
                            >
                                {uvDeviceFeatureCardData.bullet[2].title}
                            </Text>
                            <Text variant="body2" textColor="text-grey-shade1">
                                {uvDeviceFeatureCardData.bullet[2].subtitle}
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="hidden md:flex flex-col xsm:flex-row justify-center gap-5 mt-8 md:mt-14">
                    <Button
                        label="Buy Now"
                        style="primary"
                        size="large"
                        onClick={handleBuyUvDevicePlanNow}
                    />
                    <Button
                        label="Know more"
                        style="ghost"
                        size="large"
                        onClick={() => {
                            redirectTo(`/plans/${DEVICE_ONLY_PLAN_NAME}`);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

UVDeviceFeatureCard.defaultProps = {
    handleComponentChange: () => {},
    handleBuyUvDevicePlanNow: () => {},
};

UVDeviceFeatureCard.propTypes = {
    handleComponentChange: PropTypes.func,
    handleBuyUvDevicePlanNow: PropTypes.func,
    uvDeviceFeatureCardData: PropTypes.object,
    cardTitle: PropTypes.string,
};

export default UVDeviceFeatureCard;
