import PropTypes from 'prop-types';
import Image from 'next/image';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Icon from '@/atoms/Icon';
import Button from '@/atoms/Button';
import Tag from '@/atoms/Tag';

const PlanCard = ({
    cardClass,
    cardBg,
    planImg,
    planHeading,
    planPrice,
    planPriceText,
    featureList,
    listIcon,
    titleColor,
    listColor,
    handleBuyNow,
    handleRequestCallback,
    showBgImage,
    mobilePlanImg,
    btnAlignCenter,
}) => {
    const myLoader = ({ src }) => src;
    return (
        <>
            <div
                className={`${cardBg} border-2 border-grey-shade5 rounded-xl md:rounded-[40px] px-4 py-6 md:px-8 md:py-9 lg:p-10 relative overflow-hidden ${cardClass}`}
            >
                {showBgImage && (
                    <div className="absolute h-[90%] w-[50%] right-0 top-0">
                        <Image
                            loader={myLoader}
                            src="/images/plan_bg.png"
                            layout="fill"
                            alt="bg image"
                        />
                    </div>
                )}

                <Image
                    loader={myLoader}
                    src={planImg}
                    width={320}
                    height={254}
                    alt="Plan image"
                    className="mx-auto mb-5 relative z-10 hidden md:block"
                />
                <Image
                    loader={myLoader}
                    src={mobilePlanImg}
                    width={174}
                    height={174}
                    alt="Plan image"
                    className="mx-auto mb-3 relative z-10 md:hidden"
                />
                <Heading
                    type="h4"
                    fontFamily="font-body"
                    fontWeight="font-semibold"
                    fontSize="text-lg md:text-2xl/[28px]"
                    fontColor={titleColor}
                >
                    {planHeading}
                </Heading>
                <Heading
                    type="h1"
                    fontSize="text-heading2 lg:text-5xl"
                    className="py-5 border-b border-white mb-5 hidden md:block"
                    fontColor={titleColor}
                >
                    {planPrice}
                    <span className="mt-4 text-body2">{planPriceText}</span>
                </Heading>
                <Tag
                    icon={false}
                    className="text-grey-shade1 p-1 whitespace-pre mt-2 mb-4 md:hidden"
                    style="normal"
                    shape="soft"
                    size="extraSmall"
                >
                    Valid for
                    <span className="font-medium ml-1">12 weeks</span>
                </Tag>
                {featureList.map((menu, index) => (
                    <div
                        key={index}
                        className="flex pb-2 last:pb-0 gap-2 md:gap-3"
                    >
                        <Icon
                            src={listIcon}
                            width={20}
                            height={20}
                            alt="icon"
                        />
                        <Text variant="body-2" textColor={listColor}>
                            {menu.title}
                        </Text>
                    </div>
                ))}
                <Heading
                    type="h3"
                    className="py-4 md:hidden border-b border-grey-shade4"
                    fontColor={titleColor}
                >
                    {planPrice}
                </Heading>
                <div
                    className={`relative flex flex-nowrap mt-10 gap-2 xsm:gap-4 ${btnAlignCenter ? 'justify-center' : 'justify-center md:justify-start'}`}
                >
                    <Button
                        style="primary"
                        size="large4"
                        label="Buy now"
                        onClick={handleBuyNow}
                        btnClass="w-1/2 md:w-auto min-w-[152px]"
                    />
                    <Button
                        style="ghost"
                        size="large4"
                        label="Request a Callback"
                        onClick={handleRequestCallback}
                        btnClass="w-1/2 md:w-auto whitespace-nowrap shrink-0"
                    />
                </div>
            </div>
        </>
    );
};

PlanCard.defaultProps = {
    cardBg: 'bg-primary-shade3',
    cardClass: '',
    planImg: '/images/plangoup.png',
    planHeading: 'Care Plan UV Ultra',
    planPrice: '₹18,000',
    planPriceText: '/ for 12 weeks',
    featureList: [],
    listIcon: '/images/icons/icon_check.svg',
    titleColor: '',
    listColor: '',
    handleBuyNow: () => {},
    handleRequestCallback: () => {},
    showBgImage: false,
    btnAlignCenter: false,
};

PlanCard.propTypes = {
    cardBg: PropTypes.string,
    cardClass: PropTypes.string,
    planImg: PropTypes.string,
    mobilePlanImg: PropTypes.string,
    planHeading: PropTypes.string,
    planPrice: PropTypes.string,
    planPriceText: PropTypes.string,
    featureList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        })
    ),
    titleColor: PropTypes.string,
    listIcon: PropTypes.string,
    listColor: PropTypes.string,
    handleBuyNow: PropTypes.func,
    handleRequestCallback: PropTypes.func,
    showBgImage: PropTypes.bool,
    btnAlignCenter: PropTypes.bool,
};

export default PlanCard;
