import Image from 'next/image';
import PropTypes from 'prop-types';
import SectionHeading from '@/atoms/SectionHeading';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import { useEffect, useState } from 'react';
import Video from '@/molecules/CustomVideo';
import NextImage from 'next/image';
import Button from '@/atoms/Button';
import Icon from '@/atoms/Icon';
import { redirectTo } from 'utils/router';
import { DEVICE_ONLY_PLAN_NAME } from 'constants/plan-constants';

const UVDeviceVideoCard = ({
    handleComponentChange,
    handleBuyUvDevicePlanNow,
    uvDeviceVideoCardData,
    videoLink,
    cardTitle,
}) => {
    const myLoader = ({ src }) => src;
    const [showElement, setShowElement] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);

    const handleImageClick = () => {
        setShowVideo(true);
        setAutoPlay(true);
    };

    const handleCloseClick = () => {
        setShowVideo(false);
        setAutoPlay(false);
    };

    useEffect(() => {
        setShowElement(true);
    }, []);

    return (
        <div className="container">
            <div className="bg-secondary-shade3 px-4 pt-8 md:px-10 md:py-[60px] lg:p-[60px] rounded-[20px]">
                <SectionHeading
                    align="center"
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                >
                    {cardTitle}
                </SectionHeading>
                <div
                    className={`relative flex flex-wrap items-center UVDeviceVideoCard justify-between mb-20 mt-6 md:mt-[188px] md:mb-[172px] lg:mt-16 lg:mb-[60px] bg-[length:100%] bg-no-repeat rounded-xl py-6 px-4 md:py-[47px]  lg:py-[75px] md:pl-10 md:pr-[60px] lg:pr-20 transition-all fade duration-1000  ${showElement ? 'opacity-100' : 'opacity-0'} overflow-hidden`}
                >
                    <div
                        className="absolute w-full h-full inset-0"
                        onClick={handleComponentChange}
                    >
                        <NextImage
                            loader={myLoader}
                            src="/images/uv_device_bg.jpg"
                            layout="fill"
                            alt="bg image"
                        />
                    </div>
                    <div
                        className="w-full md:w-1/2 md:pr-10 relative z-10 text-center md:text-left"
                        onClick={handleComponentChange}
                        data-aos="fade-right"
                        data-aos-delay="200"
                        data-aos-once="true"
                    >
                        <Heading
                            type="h2"
                            fontColor="text-grey-shade1"
                            className="mb-2"
                        >
                            {uvDeviceVideoCardData.title}
                        </Heading>
                        <Text variant="subHeading" textColor="text-grey-shade1">
                            {uvDeviceVideoCardData.subtitle}
                        </Text>
                    </div>
                    <div
                        className="w-full md:w-1/2 relative z-10"
                        data-aos="fade-left"
                        data-aos-delay="400"
                        data-aos-once="true"
                    >
                        <div
                            onClick={handleImageClick}
                            className="cursor-pointer max-w-[240px] w-full h-[240px] mx-auto mt-11 md:mt-0 md:max-w-full md:h-auto"
                        >
                            <Image
                                loader={myLoader}
                                src={uvDeviceVideoCardData.videoThumbnail}
                                width={426}
                                height={426}
                                alt="device"
                                className="mx-auto"
                            />
                        </div>
                    </div>
                    {showVideo && (
                        <div className="w-full left-0 top-0 h-full absolute rounded-xl overflow-hidden transition-all z-10">
                            <Video
                                className="w-full h-full"
                                videoSrc={videoLink}
                                autoPlay={autoPlay}
                            />

                            <span
                                onClick={handleCloseClick}
                                className="absolute top-4 right-4 bg-black text-white rounded-full w-8 h-8 flex items-center justify-center"
                            >
                                <Icon
                                    src="/images/icons/icon_close.svg"
                                    width={44}
                                    height={44}
                                    alt="closeModal"
                                />
                            </span>
                        </div>
                    )}
                </div>
                <div className="hidden md:flex flex-col xsm:flex-row justify-center gap-5">
                    <Button
                        label="Buy Now"
                        style="primary"
                        size="large"
                        onClick={handleBuyUvDevicePlanNow}
                    />
                    <Button
                        label="Know more"
                        style="ghost"
                        size="large"
                        onClick={() => {
                            redirectTo(`/plans/${DEVICE_ONLY_PLAN_NAME}`);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

UVDeviceVideoCard.defaultProps = {
    handleComponentChange: () => {},
    handleBuyUvDevicePlanNow: () => {},
};

UVDeviceVideoCard.propTypes = {
    handleComponentChange: PropTypes.func,
    handleBuyUvDevicePlanNow: PropTypes.func,
    uvDeviceVideoCardData: PropTypes.object,
    videoLink: PropTypes.string,
    cardTitle: PropTypes.string,
};

export default UVDeviceVideoCard;
