import Heading from '@/atoms/Heading';
import Profile from '@/molecules/Profile';
import NextImage from 'next/image';
import PropTypes from 'prop-types';

const Help = ({ profileList, className, slide2Image, slide2Heading }) => {
    return (
        <div
            className={`container flex flex-col lg:flex-row gap-8 justify-center items-center ${className || ''} min-h-[670px] flex items-center`}
        >
            <div className="relative w-[30%]">
                <NextImage
                    loader={({ src }) => src}
                    src={slide2Image}
                    alt="Whole Team Coaches image"
                    width={379}
                    height={440}
                    className="hidden md:block w-[172px] lg:w-auto flex-shrink-0"
                />
            </div>
            <NextImage
                loader={({ src }) => src}
                src={slide2Image}
                alt="Whole Team Coaches image"
                width={286}
                height={335}
                className="md:hidden flex-shrink-0"
            />
            <div className="w-[70%]">
                <Heading
                    type="h3"
                    fontWeight="font-medium"
                    fontColor="text-black"
                    className="mb-6"
                >
                    {slide2Heading}
                </Heading>
                {profileList?.map((item) => (
                    <Profile
                        key={item.id}
                        availableOn={item.availableOn}
                        avatarSrc={item.avatarSrc}
                        description={item.description}
                        title={item.title}
                        className="mb-6 last:mb-0"
                    />
                ))}
            </div>
        </div>
    );
};

Help.propTypes = {
    className: PropTypes.string,
    profileList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            availableOn: PropTypes.string.isRequired,
            avatarSrc: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    slide2Image: PropTypes.string.isRequired,
    slide2Heading: PropTypes.string.isRequired,
};
export default Help;
