import { useState } from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const ReadMore = ({
    text,
    maxLength,
    variant,
    fontSize,
    fontWeight,
    textColor,
    className,
    onClick,
}) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const resultString = text
        ? isTruncated
            ? text.slice(0, maxLength) + '...'
            : text
        : '';

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <div className={className}>
            <Text
                variant={variant}
                fontSize={fontSize}
                fontWeight={fontWeight}
                textColor={textColor}
                onClick={onClick}
            >
                {resultString}
            </Text>
            {text && text.length > maxLength && (
                <Text
                    variant="body1"
                    textColor="text-blue"
                    className="my-2"
                    onClick={toggleTruncate}
                >
                    {isTruncated ? 'Read More' : 'Read Less'}
                </Text>
            )}
        </div>
    );
};

ReadMore.defaultProps = {
    maxLength: 100,
    variant: 'body3',
    fontSize: '',
    fontWeight: 'font-normal',
    textColor: 'text-grey-shade1',
    className: '',
    onClick: () => {},
};

ReadMore.propTypes = {
    text: PropTypes.string,
    maxLength: PropTypes.number,
    variant: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default ReadMore;
