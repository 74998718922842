import Icon from '@/atoms/Icon';
import Text from '@/atoms/Text';
import Image from 'next/image';
import React, { useRef, useState } from 'react';

const WeekCarePlanOverview = () => {
    const imageList = [
        {
            id: 1,
            icon: '/images/icons/icon_personalized_meal.svg',
            mainImage: '/images/carePlan_img_1.jpg',
            tabMainImage: '/images/carePlan_img_tab_1.jpg',
            mobMainImage: '/images/carePlan_img_mob_1.jpg',
            title: 'Personalised daily meal plans',
        },
        {
            id: 2,
            icon: '/images/icons/icon_home_UV_therapy.svg',
            mainImage: '/images/carePlan_img_1.jpg',
            tabMainImage: '/images/carePlan_img_tab_1.jpg',
            mobMainImage: '/images/carePlan_img_mob_1.jpg',
            title: 'At-home UV therapy',
        },
        {
            id: 3,
            icon: '/images/icons/icon_photo_symptom_tracking.svg',
            mainImage: '/images/carePlan_img_3.jpg',
            tabMainImage: '/images/carePlan_img_tab_3.jpg',
            mobMainImage: '/images/carePlan_img_mob_3.jpg',
            title: 'Photo-based symptom tracking',
        },
        {
            id: 4,
            icon: '/images/icons/icon_regular_coach_connect.svg',
            mainImage: '/images/carePlan_img_4.jpg',
            tabMainImage: '/images/carePlan_img_tab_4.jpg',
            mobMainImage: '/images/carePlan_img_mob_4.jpg',
            title: 'Regular coach connect',
        },
        {
            id: 5,
            icon: '/images/icons/icon_wellness.svg',
            mainImage: '/images/carePlan_img_5.jpg',
            tabMainImage: '/images/carePlan_img_tab_5.jpg',
            mobMainImage: '/images/carePlan_img_mob_5.jpg',
            title: 'Curated wellness exercises',
        },
        {
            id: 6,
            icon: '/images/icons/icon_diet.svg',
            mainImage: '/images/carePlan_img_6.jpg',
            tabMainImage: '/images/carePlan_img_tab_6.jpg',
            mobMainImage: '/images/carePlan_img_mob_6.jpg',
            title: 'Healthy diet challenges',
        },
        {
            id: 7,
            icon: '/images/icons/icon_tips.svg',
            mainImage: '/images/carePlan_img_7.jpg',
            tabMainImage: '/images/carePlan_img_tab_7.jpg',
            mobMainImage: '/images/carePlan_img_mob_7.jpg',
            title: 'Helpful tips and resources',
        },
    ];

    const [selectedImage, setSelectedImage] = useState(0);
    const scrollbarRef = useRef(null);

    const handleItemClick = (index) => {
        setSelectedImage(index);
        if (scrollbarRef.current) {
            const scrollPosition = index * 101;
            scrollbarRef.current.scrollLeft = scrollPosition;
        }
    };

    const myLoader = ({ src }) => src;

    return (
        <div>
            <div className="relative w-full overflow-hidden">
                <div
                    className="flex transition-transform duration-500"
                    style={{
                        transform: `translateX(-${selectedImage * 100}%)`,
                    }}
                >
                    {imageList.map((image) => (
                        <React.Fragment key={image.id}>
                            <div className="hidden lg:block min-w-full">
                                <Image
                                    loader={myLoader}
                                    src={image.mainImage}
                                    width={2880}
                                    height={1280}
                                    alt={image.title}
                                    className="object-cover h-[732px] md:h-[640px]"
                                />
                            </div>
                            <div className="hidden md:block lg:hidden min-w-full">
                                <Image
                                    loader={myLoader}
                                    src={image.tabMainImage}
                                    width={1152}
                                    height={960}
                                    alt={image.title}
                                />
                            </div>
                            <div className="md:hidden min-w-full">
                                <Image
                                    loader={myLoader}
                                    src={image.mobMainImage}
                                    width={822}
                                    height={1464}
                                    alt={image.title}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div
                className="custom-scrollbar flex bg-white max-w-[96%] xl:max-w-full xl:overflow-hidden mx-4 md:mx-10 xl:mx-0 pr-2 justify-between pb-6 list-none"
                ref={scrollbarRef}
            >
                {imageList.map((list, index) => (
                    <li
                        key={list.id}
                        onClick={() => handleItemClick(index)}
                        className={`py-4 pl-4 pr-3 md:py-5 md:pl-5 xl:py-7 xl:pl-7 md:pr-3 border-b-8 cursor-pointer min-w-[128px] md:min-w-[150px] xl:min-w-[auto] ${
                            selectedImage === index
                                ? 'bg-primary-shade3 border-primary-default'
                                : 'bg-white border-white'
                        }`}
                    >
                        <Icon
                            src={list.icon}
                            width={43}
                            height={40}
                            title={list.title}
                            className="mx-auto mb-3"
                        />
                        <Text
                            fontSize="text-caption lg:text-body2"
                            textColor="text-grey-shade1"
                            className="text-center"
                        >
                            {list.title}
                        </Text>
                    </li>
                ))}
            </div>
        </div>
    );
};

export default WeekCarePlanOverview;
