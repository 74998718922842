import { useState } from 'react';
import PropTypes from 'prop-types';
import UVDeviceFeatureCard from './UVDeviceFeatureCard';
import UVDeviceHightLightCard from './UVDeviceHightLightCard';
import UVDeviceVideoCard from './UVDeviceVideoCard';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import Button from '@/atoms/Button';
import { redirectTo } from 'utils/router';
import { DEVICE_ONLY_PLAN_NAME } from 'constants/plan-constants';

const UVDeviceSection = ({ handleBuyUvDevicePlanNow, uvDeviceSectionData }) => {
    const uvDeviceFeatureCardDatalist = [];
    const uvDeviceHighlightCardDatalist = [];
    const uvDeviceVideoCardDatalist = [];
    let videoLink;
    let cardTitle;
    uvDeviceSectionData.forEach((list) => {
        if (list.acf.type_of_slide === 'type 1') {
            cardTitle = list.acf.heading;
            let uvDeviceFeatureCardData = {
                image: list.acf.image_1.url,
                bullet: list.acf.bullets,
            };
            uvDeviceFeatureCardDatalist.push(uvDeviceFeatureCardData);
        } else if (list.acf.type_of_slide === 'type 2') {
            let uvDeviceHighlightCardData = {
                image: list.acf.image_2.url,
                bullets: list.acf.second_slide_bullets,
            };
            uvDeviceHighlightCardDatalist.push(uvDeviceHighlightCardData);
        } else if (list.acf.type_of_slide === 'type 3') {
            videoLink = list.acf.slide_3_details.video.url;
            let uvDeviceVideoCardData = {
                title: list.acf.slide_3_details.title,
                subtitle: list.acf.slide_3_details.subtitle,
                videoThumbnail: list.acf.slide_3_details.video_thumbnail.url,
            };
            uvDeviceVideoCardDatalist.push(uvDeviceVideoCardData);
        }
    });

    const [currentComponent, setCurrentComponent] = useState('feature');
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const handleComponentChange = () => {
        switch (currentComponent) {
            case 'feature':
                setCurrentComponent('highlight');
                break;
            case 'highlight':
                setCurrentComponent('video');
                break;
            default:
                setCurrentComponent('feature');
        }
    };

    const ArraySlider = ({
        ComponentToRender,
        getPropData,
        arrayData,
        onChangeKey,
        handleChange,
    }) => {
        const [currentIdx, setCurrentIdx] = useState(0);

        return [arrayData[currentIdx]].map((data, idx) => (
            <ComponentToRender
                key={idx}
                {...getPropData(data)}
                {...{
                    [onChangeKey]: (e) => {
                        if (currentIdx === arrayData.length - 1) {
                            handleChange(e);
                            setCurrentIdx(0);
                        } else {
                            setCurrentIdx((prevIdx) => {
                                const newIdx = prevIdx + 1;
                                return arrayData.length - 1 < newIdx
                                    ? 0
                                    : newIdx;
                            });
                        }
                    },
                }}
            />
        ));
    };

    return (
        <>
            <div className="hidden md:block cursor-pointer">
                {currentComponent === 'feature' && (
                    <>
                        {
                            <ArraySlider
                                arrayData={uvDeviceFeatureCardDatalist}
                                ComponentToRender={UVDeviceFeatureCard}
                                onChangeKey={'handleComponentChange'}
                                handleChange={handleComponentChange}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceFeatureCardData: data,
                                    cardTitle: cardTitle,
                                })}
                            />
                        }
                    </>
                )}
                {currentComponent === 'highlight' && (
                    <>
                        {
                            <ArraySlider
                                arrayData={uvDeviceHighlightCardDatalist}
                                ComponentToRender={UVDeviceHightLightCard}
                                onChangeKey={'handleComponentChange'}
                                handleChange={handleComponentChange}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceHighlightCardData: data,
                                    cardTitle: cardTitle,
                                })}
                            />
                        }
                    </>
                )}
                {currentComponent === 'video' && (
                    <>
                        {
                            <ArraySlider
                                arrayData={uvDeviceVideoCardDatalist}
                                ComponentToRender={UVDeviceVideoCard}
                                onChangeKey={'handleComponentChange'}
                                handleChange={handleComponentChange}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceVideoCardData: data,
                                    cardTitle: cardTitle,
                                    videoLink: videoLink,
                                })}
                            />
                        }
                    </>
                )}
            </div>
            <div
                className={`md:hidden pb-8 ${activeIndex === 0 && 'bg-grey-shade6'} ${activeIndex === 1 && 'bg-secondary-shade3'} ${activeIndex === 2 && 'bg-secondary-shade3'} ${activeIndex === 3 && 'bg-secondary-shade3'}`}
            >
                <Slider
                    slidesPerView={1}
                    style={{ paddingBottom: '20px' }}
                    loop={false}
                    onSlideChange={handleSlideChange}
                >
                    {uvDeviceFeatureCardDatalist.map((data, idx) => (
                        <SwiperSlide key={idx}>
                            <UVDeviceFeatureCard
                                uvDeviceFeatureCardData={data}
                                cardTitle={cardTitle}
                            />
                        </SwiperSlide>
                    ))}
                    {uvDeviceHighlightCardDatalist.map((data, idx) => (
                        <SwiperSlide key={idx}>
                            <UVDeviceHightLightCard
                                showFirstList={true}
                                showSecondList={false}
                                uvDeviceHighlightCardData={data}
                                cardTitle={cardTitle}
                            />
                        </SwiperSlide>
                    ))}
                    {uvDeviceHighlightCardDatalist.map((data, idx) => (
                        <SwiperSlide key={idx}>
                            <UVDeviceHightLightCard
                                showFirstList={false}
                                showSecondList={true}
                                uvDeviceHighlightCardData={data}
                                cardTitle={cardTitle}
                            />
                        </SwiperSlide>
                    ))}
                    {uvDeviceVideoCardDatalist.map((data, idx) => (
                        <SwiperSlide key={idx}>
                            <UVDeviceVideoCard
                                uvDeviceVideoCardData={data}
                                videoLink={videoLink}
                                cardTitle={cardTitle}
                            />
                        </SwiperSlide>
                    ))}
                </Slider>
                <div className="container">
                    <div className="flex flex-col xsm:flex-row justify-center gap-5 mt-8 md:mt-14">
                        <Button
                            label="Buy Now"
                            style="primary"
                            size="large"
                            onClick={handleBuyUvDevicePlanNow}
                        />
                        <Button
                            label="Know more"
                            style="ghost"
                            size="large"
                            onClick={() => {
                                redirectTo(`/plans/${DEVICE_ONLY_PLAN_NAME}`);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

UVDeviceSection.defaultProps = {
    handleBuyUvDevicePlanNow: () => {},
};

UVDeviceSection.propTypes = {
    handleBuyUvDevicePlanNow: PropTypes.func,
    uvDeviceSectionData: PropTypes.array,
};

export default UVDeviceSection;
